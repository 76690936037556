import React, { useState } from "react";
import css from "./main.module.css";
import Swal from "sweetalert2";
import emailjs from "@emailjs/browser";
import { AiOutlineClose } from "react-icons/ai";
const Footer = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({ name: "", phone: "" });

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Валідація номера телефону
    const phoneRegex = /^\+?\d{10,14}$/;
    if (!phoneRegex.test(formData.phone)) {
      Swal.fire({
        icon: "error",
        title: "Невірний номер телефону",
        text: "Будь ласка, введіть коректний номер телефону у форматі: +380XXXXXXXXX.",
      });
      return;
    }

    const templateParams = {
      user_name: formData.name,
      Phone: formData.phone,
      Subject: "Замовлення дзвінка",
    };

    Swal.fire({
      title: "Надсилання...",
      text: "Зачекайте, будь ласка, йде відправка даних.",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    emailjs
      .send(
        "service_xh5ymdk", // Ваш SERVICE ID
        "template_zqbdjvp", // Ваш TEMPLATE ID
        templateParams,
        "My9e5IjpQ2yIY3t12" // Ваш PUBLIC KEY
      )
      .then(
        (result) => {
          Swal.fire({
            icon: "success",
            title: "Відправлено!",
            text: "Ваш запит на дзвінок успішно надіслано! Наш менеджер Вам зателефонує.",
          });
          setIsModalOpen(false);
        },
        (error) => {
          Swal.fire({
            icon: "error",
            title: "Помилка",
            text: "Сталася помилка при відправці. Спробуйте ще раз.",
          });
        }
      );
  };

  return (
    <div className={css.wrapFooter}>
      <div
        className={css.buttonPost}
        onClick={() => setIsModalOpen(true)} // Відкриття попапу при кліку
      >
        Замовити дзвінок
      </div>
      <a href="tel:+380937246193" className={css.linkNumberK}>
        +380937246193
      </a>

      {isModalOpen && (
        <div className={css.modalBackdrop}>
          <div className={css.modalContent}>
            <AiOutlineClose
              onClick={() => setIsModalOpen(false)}
              className={css.closeButton}
            />

            <form onSubmit={handleSubmit}>
              <input
                type="text"
                name="name"
                placeholder="Ваше ім'я"
                className={css.inputFieldPop}
                value={formData.name}
                onChange={handleInputChange}
                required
              />
              <input
                type="tel"
                name="phone"
                placeholder="Ваш номер телефону"
                className={css.inputFieldPop}
                value={formData.phone}
                onChange={handleInputChange}
                required
              />
              <button type="submit" className={css.submitButton}>
                Відправити
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Footer;
