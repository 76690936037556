// import React, { useState } from "react";
// import css from "./main.module.css";
// import fl from "../video/videoPodds.mp4";
// import poster from "../img/dsa.jpg"; // шлях до вашої картинки заставки
// import { BsCalculator } from "react-icons/bs";
// import { FaBoxOpen } from "react-icons/fa";
// import { FaClipboardList } from "react-icons/fa";
// import { ReactSVG } from "react-svg";
// import butPlay from "../img/buttonPlayVidep.svg";
// const SecondBlock = ({ setQuiz }) => {
//   const [isPlaying, setIsPlaying] = useState(false); // Стан для контролю відтворення відео

//   const handlePlay = () => {
//     setIsPlaying(true); // Змінюємо стан на "відтворення"
//   };

//   return (
//     <div className={css.wrapSecondBlock}>
//       <div className={css.videoWrap}>
//         {!isPlaying ? (
//           <div className={css.posterWrap}>
//             <img src={poster} alt="Poster" className={css.posterImage} />

//             <ReactSVG
//               className={css.buttonPlayVideo}
//               src={butPlay}
//               onClick={handlePlay}
//             />
//           </div>
//         ) : (
//           <video className={css.videoPlayer} controls autoPlay>
//             <source src={fl} type="video/mp4" />
//             Ваш браузер не підтримує відео.
//           </video>
//         )}
//       </div>
//       <div className={css.wrapBlock}>
//         {" "}
//         <div className={css.wrapPblock} onClick={() => setQuiz(true)}>
//           {" "}
//           <BsCalculator className={css.aCalculator} />
//           Розрахувати вартість сайту
//         </div>
//         <div className={css.wrapTwoButton}>
//           <div className={css.smallButtonQw} onClick={() => setQuiz(true)}>
//             <FaBoxOpen className={css.aBoxOpen} />
//             <p className={css.pInSmallE}>
//               Безкоштовний аналіз Ваших конкурентів
//             </p>
//           </div>
//           <div className={css.smallButtonQw} onClick={() => setQuiz(true)}>
//             <FaClipboardList className={css.aBoxOpen} />{" "}
//             <p className={css.pInSmallE}>
//               Список корисних сервісів для бізнесу
//             </p>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default SecondBlock;
import React, { useState, useEffect, useRef } from "react";
import css from "./main.module.css";
import fl from "../video/videoPodds.mp4";
import poster from "../img/dsa.jpg"; // шлях до вашої картинки заставки
import { BsCalculator } from "react-icons/bs";
import { FaBoxOpen } from "react-icons/fa";
import { FaClipboardList } from "react-icons/fa";
import { ReactSVG } from "react-svg";
import butPlay from "../img/buttonPlayVidep.svg";

const SecondBlock = ({ setQuiz }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsPlaying(true); // Змінюємо стан на "відтворення", коли елемент потрапляє у видиму область
          }
        });
      },
      { threshold: 0.5 } // Відео повинно бути видно на 50%, щоб запустити
    );

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  return (
    <div className={css.wrapSecondBlock}>
      <div className={css.videoWrap} ref={videoRef}>
        {!isPlaying ? (
          <div className={css.posterWrap}>
            <img src={poster} alt="Poster" className={css.posterImage} />
            <ReactSVG
              className={css.buttonPlayVideo}
              src={butPlay}
              onClick={() => setIsPlaying(true)}
            />
          </div>
        ) : (
          <video className={css.videoPlayer} controls autoPlay>
            <source src={fl} type="video/mp4" />
            Ваш браузер не підтримує відео.
          </video>
        )}
      </div>
      <div className={css.wrapBlock}>
        <div className={css.wrapPblock} onClick={() => setQuiz(true)}>
          <BsCalculator className={css.aCalculator} />
          Розрахувати вартість сайту
        </div>
        <div className={css.wrapTwoButton}>
          <div className={css.smallButtonQw} onClick={() => setQuiz(true)}>
            <FaBoxOpen className={css.aBoxOpen} />
            <p className={css.pInSmallE}>
              Безкоштовний аналіз Ваших конкурентів
            </p>
          </div>
          <div className={css.smallButtonQw} onClick={() => setQuiz(true)}>
            <FaClipboardList className={css.aBoxOpen} />{" "}
            <p className={css.pInSmallE}>
              Список корисних сервісів для бізнесу
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecondBlock;
