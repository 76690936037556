import logo from "./logo.svg";
import "./App.css";
import Header from "./components/header";
import FirstBlock from "./components/firstBlock";
import SecondBlock from "./components/secondBlock";
import ThreBlock from "./components/threBlock";
import Footer from "./components/footer";
import { useState } from "react";
import Quiz from "./components/quiz";

function App() {
  const [quiz, setQuiz] = useState(false);
  return (
    <>
      <Header />
      <FirstBlock setQuiz={setQuiz} />
      <SecondBlock setQuiz={setQuiz} />
      <ThreBlock setQuiz={setQuiz} />
      <Footer />
      {quiz && <Quiz setQuiz={setQuiz} />}
    </>
  );
}

export default App;
