import React, { useState, useEffect } from "react";
import css from "./main.module.css";
import { AiOutlineClose } from "react-icons/ai";
import { BsCalculator } from "react-icons/bs";
import { FaBoxOpen } from "react-icons/fa";
import { FaClipboardList } from "react-icons/fa";
import { AiOutlineClockCircle } from "react-icons/ai";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";

const Quiz = ({ setQuiz }) => {
  const [timeLeft, setTimeLeft] = useState(() => {
    const savedTime = localStorage.getItem("quizEndTime");
    return savedTime ? Math.max(0, savedTime - Date.now()) : 300000; // 5 хвилин у мілісекундах
  });

  const [currentStep, setCurrentStep] = useState(0);
  const [answers, setAnswers] = useState({});
  const [formData, setFormData] = useState({ name: "", phone: "" });
  const [otherInput, setOtherInput] = useState("");
  const [showOtherInput, setShowOtherInput] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState("");

  useEffect(() => {
    if (timeLeft > 0) {
      const intervalId = setInterval(() => {
        setTimeLeft((prevTime) => {
          const newTimeLeft = prevTime - 1000;
          if (newTimeLeft <= 0) {
            localStorage.removeItem("quizEndTime");
            clearInterval(intervalId);
            return 0;
          }
          return newTimeLeft;
        });
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [timeLeft]);

  useEffect(() => {
    if (timeLeft > 0) {
      const endTime = Date.now() + timeLeft;
      localStorage.setItem("quizEndTime", endTime);
    }
  }, [timeLeft]);

  const formatTime = (milliseconds) => {
    const minutes = Math.floor(milliseconds / 60000);
    const seconds = Math.floor((milliseconds % 60000) / 1000);
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };

  const handleAnswer = (question, answer) => {
    setSelectedAnswer(answer);
    if (answer.includes("Інше")) {
      setShowOtherInput(true);
    } else {
      setShowOtherInput(false);
    }
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleOtherInputChange = (e) => {
    setOtherInput(e.target.value);
  };

  const handleSubmitOther = () => {
    const currentQuestion = questions[currentStep].question;
    setAnswers({ ...answers, [currentQuestion]: otherInput });
    setShowOtherInput(false);
    setCurrentStep(currentStep + 1);
    setSelectedAnswer("");
  };

  const handleNext = () => {
    const currentQuestion = questions[currentStep].question;
    setAnswers({ ...answers, [currentQuestion]: selectedAnswer });
    setCurrentStep(currentStep + 1);
    setSelectedAnswer("");
  };

  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
      setSelectedAnswer(answers[questions[currentStep - 1].question] || "");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Валідація номера телефону
    const phoneRegex = /^\+?\d{10,14}$/;
    if (!phoneRegex.test(formData.phone)) {
      Swal.fire({
        icon: "error",
        title: "Невірний номер телефону",
        text: "Будь ласка, введіть коректний номер телефону у форматі: +380XXXXXXXXX.",
      });
      return;
    }

    const message = `
      1. Яка основна мета вашого сайту? - ${
        answers[questions[0].question] || ""
      }
      2. Який тип сайту вам потрібен? - ${answers[questions[1].question] || ""}
      3. Який бюджет ви готові виділити на розробку сайту? - ${
        answers[questions[2].question] || ""
      }
    `;

    const templateParams = {
      user_name: formData.name,
      Phone: formData.phone,
      Subject: "Лід з квіза",
      message: message.trim(),
    };

    Swal.fire({
      title: "Надсилання...",
      text: "Зачекайте, будь ласка, йде відправка даних.",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    emailjs
      .send(
        "service_xh5ymdk",
        "template_zqbdjvp",
        templateParams,
        "My9e5IjpQ2yIY3t12"
      )
      .then(
        (result) => {
          Swal.fire({
            icon: "success",
            title: "Відправлено!",
            text: "Ваші відповіді надіслано успішно! Наш менеджер Вам зателефонує",
          });
          setQuiz(false);
        },
        (error) => {
          Swal.fire({
            icon: "error",
            title: "Помилка",
            text: "Сталася помилка при відправці. Спробуйте ще раз.",
          });
        }
      );
  };

  const questions = [
    {
      question: "Яка основна мета вашого сайту?",
      options: [
        "Продаж товарів або послуг",
        "Надання інформації про компанію",
        "Блог або новинний ресурс",
        "Інше (вкажіть)",
      ],
    },
    {
      question: "Який тип сайту вам потрібен?",
      options: [
        "Лендінг (односторінковий сайт)",
        "Корпоративний сайт",
        "Інтернет-магазин",
        "Портфоліо або галерея робіт",
        "Інше (вкажіть)",
      ],
    },
    {
      question: "Який бюджет ви готові виділити на розробку сайту?",
      options: [
        "Менше $1000",
        "$1000 - $3000",
        "$3000 - $5000",
        "Більше $5000",
      ],
    },
  ];

  return (
    <div className={css.wrapQuiz}>
      <AiOutlineClose
        className={css.iOutlineClose}
        onClick={() => setQuiz(false)}
      />
      <div className={css.allWrapP}>
        <div className={css.questionsWrap}>
          {currentStep < questions.length ? (
            <>
              <div className={css.progressBar}>
                <div
                  className={css.progress}
                  style={{
                    width: `${
                      ((currentStep + 1) / (questions.length + 1)) * 100
                    }%`,
                  }}
                ></div>
              </div>
              <p className={css.stepWrapP}>{questions[currentStep].question}</p>
              {questions[currentStep].options.map((option, index) => (
                <label key={index} className={css.optionLabel}>
                  <input
                    type="radio"
                    name={`question-${currentStep}`}
                    value={option}
                    checked={selectedAnswer === option}
                    onChange={() =>
                      handleAnswer(questions[currentStep].question, option)
                    }
                    className={css.radioInput}
                  />
                  <span className={css.customRadio}></span>
                  <span className={css.radioText}>{option}</span>
                </label>
              ))}
              {showOtherInput && (
                <>
                  <input
                    type="text"
                    placeholder="Введіть ваш варіант"
                    className={css.inputField}
                    value={otherInput}
                    onChange={handleOtherInputChange}
                  />
                  <button
                    className={css.submitButton}
                    onClick={handleSubmitOther}
                  >
                    Зберегти
                  </button>
                </>
              )}
              <div className={css.navigationButtons}>
                <button
                  onClick={handlePrevious}
                  className={css.prevButton}
                  disabled={currentStep === 0}
                >
                  Назад
                </button>
                <button
                  onClick={handleNext}
                  className={css.nextButton}
                  disabled={!selectedAnswer}
                >
                  Наступне
                </button>
              </div>
            </>
          ) : (
            <form onSubmit={handleSubmit}>
              <p className={css.stepWrapP}>
                Для завершення підрахунку заповніть данні
              </p>
              <input
                type="text"
                name="name"
                placeholder="Ваше ім'я"
                className={css.inputField}
                value={formData.name}
                onChange={handleInputChange}
                required
              />
              <input
                type="tel"
                name="phone"
                placeholder="Ваш номер телефону"
                className={css.inputField}
                value={formData.phone}
                onChange={handleInputChange}
                required
              />
              <button type="submit" className={css.submitButton}>
                Відправити
              </button>
            </form>
          )}
        </div>
        <div className={css.wrapSatndartInQuiz}>
          <div className={css.wrapTimer}>
            <div className={css.wrapTime}>
              <AiOutlineClockCircle className={css.iOutlineClockCircle} />
              <p className={css.timesert}>{formatTime(timeLeft)}</p>
            </div>
            <p className={css.fdp}>
              Відповідайте на запитання, щоб отримати бонуси та оцінити.
            </p>
          </div>
          <div className={css.smallButtonQw}>
            <FaBoxOpen className={css.aBoxOpen} />
            <p className={css.pInSmallE}>
              Безкоштовний аналіз Ваших конкурентів
            </p>
          </div>
          <div className={css.smallButtonQw}>
            <FaClipboardList className={css.aBoxOpen} />
            <p className={css.pInSmallE}>
              Список корисних сервісів для бізнесу
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Quiz;
