import css from "./main.module.css";
import { BsCalculator } from "react-icons/bs";
import { FaBoxOpen } from "react-icons/fa";
import { FaClipboardList } from "react-icons/fa";

const FirstBlock = ({ setQuiz }) => {
  return (
    <div className={css.wrapFirstBlock}>
      <div className={css.shadowBlock}></div>
      <div className={css.wrapBlock}>
        <div className={css.wrapListQuiz}>
          <p className={css.fastCalc}>
            Розрахуйте вартість розробки{" "}
            <span className={css.spanMkl}>Вашого сайту</span>
          </p>
          <p className={css.smalDescl}>
            Дайте відповідь на 4 простих запитання та отримайте{" "}
            <span className={css.spanSmall}>
              безкоштовний аналіз Ваших конкурентів
            </span>{" "}
          </p>
        </div>{" "}
        <div className={css.wrapPblock} onClick={() => setQuiz(true)}>
          {" "}
          <BsCalculator className={css.aCalculator} />
          Розрахувати вартість сайту
        </div>
        <div className={css.wrapTwoButton}>
          <div className={css.smallButtonQw} onClick={() => setQuiz(true)}>
            <FaBoxOpen className={css.aBoxOpen} />
            <p className={css.pInSmallE}>
              Безкоштовний аналіз Ваших конкурентів
            </p>
          </div>
          <div className={css.smallButtonQw} onClick={() => setQuiz(true)}>
            <FaClipboardList className={css.aBoxOpen} />{" "}
            <p className={css.pInSmallE}>
              Список корисних сервісів для бізнесу
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FirstBlock;
