import React from "react";
import Slider from "react-slick";
import css from "./main.module.css"; // Ваші стилі для слайдера
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import car from "../img/works/carrentujkf.png";
import beton from "../img/works/beton.png";
import essay from "../img/works/Essay-writing.png";
import chas from "../img/works/chas.png";
import jaivir from "../img/works/gaivirP.png";
import nty from "../img/works/ntybiz.png";
import exac from "../img/works/exact.png";
import iron from "../img/works/tatusolon.png";
import rendere from "../img/works/renderst.png";
import worktit from "../img/works/worktitle.jpg";
import { BsCalculator } from "react-icons/bs";
import { FaBoxOpen } from "react-icons/fa";
import { FaClipboardList } from "react-icons/fa";
import { ReactSVG } from "react-svg";
// Компоненти для кастомних кнопок
const NextArrow = ({ onClick }) => {
  return (
    <div className={css.nextArrow} onClick={onClick}>
      ▶
    </div>
  );
};

const PrevArrow = ({ onClick }) => {
  return (
    <div className={css.prevArrow} onClick={onClick}>
      ◀
    </div>
  );
};

const ThreBlock = ({ setQuiz }) => {
  const sliderSettings = {
    dots: false, // Вимикаємо стандартні точки навігації
    infinite: true,
    speed: 500,
    slidesToShow: 1, // Відображаємо одне зображення повністю
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <NextArrow />, // Додаємо кнопку "Вперед"
    prevArrow: <PrevArrow />, // Додаємо кнопку "Назад"
    centerMode: false, // Вимикаємо центральний режим, щоб відображалася лише одна картинка
  };

  const slidesData = [
    {
      image: rendere,
      link: "https://www.miragevisualisation.com/",
      description: "Сайт для компанії по архітектурній візуалізації",
    },
    {
      image: worktit,
      link: "http://webui.com.ua/ua/portfolio/sait-po-vizualizatsii",
      description: "Сайт для студії з візуалізації",
    },
    {
      image: car,
      link: "http://webui.com.ua/ua/portfolio/car-rent",
      description: "Сайт для компанії з оренди авто",
    },
    {
      image: beton,
      link: "http://webui.com.ua/ua/portfolio/sait-dlya-betonu",
      description: "Сайт для компанії з виробництва бетону",
    },
    {
      image: essay,
      link: "http://webui.com.ua/ua/portfolio/napysannya-statey",
      description: "Платформа для пошуку виконавців написання робіт",
    },
    {
      image: chas,
      link: "https://chas-maistriv.com.ua/",
      description: "Інтернет-магазин для книжкового видавництва",
    },
    {
      image: jaivir,
      link: "https://jaivir.com/",
      description: "Сайт для кондитерського виробництва",
    },
    {
      image: nty,
      link: "https://nty.ua/",
      description: "Сайт для компанії з перевезень",
    },

    {
      image: exac,
      link: "https://www.exact.com/",
      description: "Сайт для компанії з бухгалтерських послуг",
    },
    {
      image: iron,
      link: "https://www.ironbuzztattoos.com/",
      description: "Сайт для тату салону",
    },
  ];

  return (
    <>
      <div className={css.sliderContainer}>
        <Slider {...sliderSettings}>
          {slidesData.map((slide, index) => (
            <a
              href={slide.link}
              target="_blank"
              rel="noopener noreferrer"
              key={index}
            >
              <div className={css.slide}>
                <img
                  src={slide.image}
                  alt={`Slide ${index + 1}`}
                  className={css.slideImage}
                />
                <div className={css.description}>{slide.description}</div>
              </div>
            </a>
          ))}
        </Slider>
      </div>
      <div className={css.wrapBlock}>
        {" "}
        <div className={css.wrapPblock} onClick={() => setQuiz(true)}>
          {" "}
          <BsCalculator className={css.aCalculator} />
          Розрахувати вартість сайту
        </div>
        <div className={css.wrapTwoButton}>
          <div className={css.smallButtonQw} onClick={() => setQuiz(true)}>
            <FaBoxOpen className={css.aBoxOpen} />
            <p className={css.pInSmallE}>
              Безкоштовний аналіз Ваших конкурентів
            </p>
          </div>
          <div className={css.smallButtonQw} onClick={() => setQuiz(true)}>
            <FaClipboardList className={css.aBoxOpen} />{" "}
            <p className={css.pInSmallE}>
              Список корисних сервісів для бізнесу
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ThreBlock;
