import css from "./main.module.css";
import { AiFillPhone } from "react-icons/ai";

const Header = () => {
  return (
    <div className={css.headerWrap}>
      <p className={css.logo}>
        WebUi-<span className={css.logoSpan}>studio</span>
      </p>
      <div className={css.wrapPhone}>
        <AiFillPhone className={css.iFillPhone} />
        <a href="" className={css.phoneA}>
          +380937246193
        </a>
      </div>
    </div>
  );
};
export default Header;
